<template>
  <div
      :class="isClosed ? '' : 'sel'"
      class="elz p-rel d-block mB16 bor1 r3 bg bg-primary bgL5 br br-primary brL-10 brLInvD hideSelOut showSelOut"
  >
    <div
        class="elz d-flex gap8 a-H p16 opAct07 cur-pointer"
        @click="toggleWorkItem"
    >
      <div class="elz d-flex f-wrap a-H grow gap8">
        <div class="elz d-block grow fb320 lh12">
          <div class="elz d-flex a-H">
            <div
                :class="statusClass"
                class="elz d-block mR8 pH8 lh10 fn10 bold rRound pV4 pH6 bg fn"
                style="min-width: 64px; text-align: center;"
            >
              {{ statusTitle }}
            </div>
            <div class="elz d-block">
              Работа #{{ work.ScoreWorkID }}
            </div>
          </div>
          <div class="elz d-flex f-wrap gapH16 gapV4 fn11 mT6 nowrap">
            <div class="elz d-block">
              Начато: <b class="elz bold">{{ setDateTime(work.StartedAt, 'Время не задано') }}</b>
            </div>
            <div class="elz d-block">
              Завершено: <b class="elz bold">{{ setDateTime(work.FinishedAt, 'Время не задано') }}</b>
            </div>
          </div>
        </div>
        <div class="elz d-flex f-wrap fn12 gapH16 gapV8">
          <div
              class="elz d-flex a-H gap8 wmn64 cur-help"
              :title="`Участников: ${work.UserCount}`"
          >
            <div
                class="elz p-rel d-block p-rel mskBef s16 cFillBef bgBef-CC msk-contain"
                style="--elzMsk: url('/style/icons/user.svg');"
            />
            <div class="elz d-block bold">
              {{ work.UserCount }}
            </div>
          </div>
          <div
              class="elz d-flex a-H gap8 wmn64 cur-help"
              :title="`Баллов начислено: ${+work.ScoreTotal.toFixed(2)}`"
          >
            <div
                class="elz p-rel d-block p-rel mskBef s16 cFillBef bgBef-CC msk-contain"
                style="--elzMsk: url('/style/icons/star3.svg');"
            />
            <div class="elz d-block bold">
              {{ +work.ScoreTotal.toFixed(2) }}
            </div>
          </div>
          <div
              class="elz d-flex a-H gap8 wmn64 cur-help"
              :title="`Работ выполняется: ${work.ServiceCount}`"
          >
            <div
                class="elz p-rel d-block p-rel mskBef w20 h16 cFillBef bgBef-CC msk-contain"
                style="--elzMsk: url('/style/icons/hammer-wrench.svg');"
            />
            <div class="elz d-block bold">
              {{ work.ServiceCount }}
            </div>
          </div>
        </div>
      </div>
      <div
          class="elz p-rel d-block noShrink p-rel mskBef s8 deg180 cFillBef bgBef-CC hideSelIn"
          style="--elzMsk: url('/style/icons/arrow1.svg');"
      />
      <div
          class="elz p-rel d-block noShrink p-rel mskBef s8 cFillBef bgBef-CC showSelIn"
          style="--elzMsk: url('/style/icons/arrow1.svg');"
      />
    </div>
    <div
        v-if="work.hasDetails"
        class="elz d-block borT1 br br-primary brL-10 brLInvD fn12 showSelIn"
    >
      <div class="elz p-sticky p-TEP pH16 pV8 mV16 z10 bg bg-primary bgL5">
        <div class="elz p-rel d-flex pH36 f-wrap a-X gap8">
          <ButtonBase
              :class-list="['changeWorkStatus hmn36 ', buttonClass]"
              :title="buttonTitle"
              @onButtonClick="confirmChangeWorkStatus"
          />
          <template v-for="(btn, i) in additionButtons"
                    :key="i"
          >
            <ButtonBase
                :class-list="['changeWorkStatus hmn36 ', btn.buttonClass]"
                :title="btn.buttonTitle"
                v-if="btn.onlyForAdmins && isAdmin"
                @onButtonClick="additionButtonAction(btn.action)"
            />
          </template>
          <div
              v-if="+work.ScoreWorkStatusID === 1"
              class="deleteWorkItem elz p-abs p-R d-flex gap8 a-X al-center r3 s36 cur-pointer opAct07 bg bg-error bgHovL10 fn fn-error-t"
              @click="confirmDeleteAction"
          >
            <div
                class="elz p-rel d-block p-rel mskBef s16 cFillBef bgBef-CC"
                :style="`--elzMsk: url('/style/icons/${deleteWorkItem ? 'trash' : 'cross1'}.svg');`"
            />
          </div>
        </div>
      </div>
      <div class="elz d-grid gap8 grH240 pH16">
        <div
            v-for="participant in participants"
            :key="participant.UserID"
            :class="(participant.disabled) ? 'uDisabled' : ''"
            class="p-rel d-flex a-H r3 bor1 bg bg-primary br br-primary brL-10 brHovL-15 brLInvD"
        >
          <template v-if="participant.showUploader">
            <Uploader
                :circle-size="'s32'"
                :circle-width="'2'"
                :view-settings="'p-abs p16 r3 z10 bg bg-primary bgL5 br br-primary brL-10 brLInvD bgA50'"
            />
          </template>

          <label
              :class="participant.StoppedAt ? 'hide' : 'or1'"
              class="p-rel d-flex a-X pV12 pH16 s-SV cur-pointer or1"
              title="Назначить / снять участника"
          >
            <input
                type="checkbox"
                :checked="!!participant.UserSelected"
                class="elz elzCheck checkbox p-rel d-flex noShrink cur-pointer bor1 s24 p4 r2 cLInvD bg bg-primary bgL10 br br-primary brL-10 brHovL-20 fn fn-primary-t fnHovL-5 bshAct-inset1"
                @input="(e) => participantToggle(e, participant.UserID)"
            >
          </label>

          <div class="elz d-block growX hmn32 pV12 or2">
            <div class="elz d-block bold lh12 oH ellipsis nowrap">
              {{ participant.UserName }}
            </div>
            <div class="elz d-block fn11 mT4">
              {{ setDateTime(participant.CreatedAt, 'Не назначен') }}
            </div>
          </div>

          <div
              v-if="participant.UserSelected"
              :class="participant.StoppedAt ? 'or1' : 'or3'"
              class="elz d-flex a-PR wmn56 pR12 s-SV cur-pointer opAct07 fnHov fnHov-primary-t fnHovL-20 fnHovLInvD"
              title="Завершить участие"
              @click="participantFinish(participant.UserID)"
          >
            <div class="elz d-flex a-X noShrink r3 s32">
              <div class="elz d-flex a-X rRound bor2 s20 noShrink br-CC">
                <div
                    class="elz p-rel d-block p-rel noShrink mskBef s8 cFillBef bgBef-CC"
                    style="--elzMsk: url('/style/icons/stop.svg');"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="elz d-flex gapH16 gapV32 f-wrap">
        <FileUploadWrap
            name="works"
            title="Загрузить файл"
            :file-list="files"
            icon-name="rules"
            sub-title="Нажмите чтобы загрузить файл"
            :disabled-input="disabledUploader"
            @uploadFile="uploadFile"
        />
      </div>

      <div
          v-if="showFullServices"
          class="elz d-block mT16 r3 oH"
      >
        <template
            v-for="(list) in scoreServices"
            :key="list.categoryId"
        >
          <CheckboxInputFieldWrapper
              :is-disabled="false"
              :items-list="list"
              :items-selected="workServices"
              @updateServicesList="(id, checked) => $emit('updateServicesList', id, checked, list)"
              @updateServiceCount="(id, count) => $emit('updateServiceCount', id, count, list)"
          />


          <template v-if="list.showUploader===true">
            <Uploader
                :circle-size="'s32'"
                :circle-width="'2'"
                :view-settings="'p-abs p16 r3 z10 bg bg-primary bgL5 br br-primary brL-10 brLInvD bgA50'"
            />
          </template>
        </template>
      </div>

      <div
          v-else
          class="elz d-block mT16 r3 oH"
      >
        <template
            v-for="(list) in loggedServices"
            :key="list.name"
        >
          <CheckboxInputFieldWrapper
              :is-disabled="true"
              :items-list="list"
              :items-selected="workServices"
          />
        </template>
      </div>
    </div>

    <template v-if="showUploader">
      <Uploader
          :circle-size="'s32'"
          :circle-width="'2'"
          :view-settings="'p-abs p16 r3 z10 bg bg-primary bgL5 br br-primary brL-10 brLInvD bgA50'"
      />
    </template>
  </div>
</template>

<script>
import ButtonBase from '@/components/elements/ButtonBase';
import FileUploadWrap from '@/components/elements/FileUploadWrap';
import CheckboxInputFieldWrapper from '@/components/elements/CheckboxInputFieldWrapper';
import clickOut from '@/mixins/clickOut';
import { dateFormatDdMmYyyy, dateTimeFormatHHMM } from '@/helpers/formating';
import { mapGetters } from 'vuex';


export default {

  name: 'OrderWorksItem',

  components: {
    ButtonBase,
    CheckboxInputFieldWrapper,
    FileUploadWrap,
  },

  mixins: [ clickOut ],

  props: {
    work: { required: true, type: Object },
  },

  emits: [ 'changeWorkStatus', 'deleteWorkItem', 'participantFinish', 'participantToggle', 'updateServiceCount', 'updateServicesList' ],

  data() {
    return {
      isClosed: true,
      deleteWorkItem: false,
      changeWorkStatus: false,
      showUploader: false,
    };
  },

  computed: {
    ...mapGetters({
      isAdmin: 'static/isAdmin',
    }),

    workStatusProps() {
      return {
        1: {
          statusId: 1,
          buttonClass: 'bg-ok bgHovL10 fn-ok-t',
          buttonTitle: 'Начать работу',
          tagClass: 'bg-ok fn-ok-t',
        },
        2: {
          statusId: 2,
          buttonClass: 'bg-success bgHovL10 fn-success-t',
          buttonTitle: 'Завершить работу',
          tagClass: 'bg-success fn-success-t',
        },
        3: {
          statusId: 3,
          buttonClass: 'bg-primary-t fn-primary bgLInvD bgHovL-10 uDisabled',
          buttonTitle: 'Работы завершены',
          tagClass: 'bg-primary-t fn-primary',
        },
        4: {
          statusId: 4,
          buttonClass: 'bg-error fn-error-t bgL-5 bgLInvD bgHovL-10 uDisabled',
          buttonTitle: 'Работы отменены',
          tagClass: 'bg-error fn-error-t ',
        },
        5: {
          statusId: 5,
          buttonClass: `bg-purple bgHovL10 fn-purple-t ${ this.isAdmin ? '' : 'uDisabled' }`,
          buttonTitle: 'Сохранить изменения',
          tagClass: 'bg-purple fn-purple-t',
        },
      };
    },

    currentStatusProps() {
      return this.workStatusProps[this.work.ScoreWorkStatusID];
    },

    workId() {
      return this.work.ScoreWorkID;
    },

    files() {
      if ( this.$store.state.orderPage.files.works ) {
        return this.$store.state.orderPage.files.works.filter(file => file.work_id == this.workId);
      }
      return [];
    },

    orderDetails() {
      return this.$store.state.orderPage.order.details;
    },

    scoreServices() {
      return this.$store.state.static.scoreServices;
    },

    workServices() {
      return this.work.workServices;
    },

    buttonClass() {
      return this.currentStatusProps?.buttonClass;

      // return this.scoreWorksStatusOptions(this.work.ScoreWorkStatusID)?.buttonClass;
    },

    additionButtons() {
      if ( parseInt(this.work.ScoreWorkStatusID) === 3 ) {
        return [ {
          action: 'changeWork',
          buttonClass: 'bg-purple bgHovL10 fn-purple-t',
          buttonTitle: 'Изменить работу',
          tagClass: 'bg-purple fn-purple-t',
          onlyForAdmins: true,
        } ];
      }
      return [];
    },

    buttonTitle() {
      return this.currentStatusProps?.buttonTitle || '';

      // let title = this.scoreWorksStatusOptions(this.work.ScoreWorkStatusID)?.buttonTitle;
      // return title += this.changeWorkStatus ? '?' : '';
    },

    loggedServices() {
      let services = [];

      if ( this.work?.workServices?.length ) {
        const list = [];
        this.work.workServices.forEach(({ scoreServiceId, serviceScore, serviceTitle }) => {
          list.push({
            id: scoreServiceId,
            name: serviceTitle,
            value: serviceScore,
          });
        });

        list.sort(function(a, b) {
          return a.id - b.id;
        });

        services.push({
          categoryId: 0,
          name: 'Выбранные работы',
          list: list,
        });
      }

      return services;
    },

    // participantList() {
    //   return (this.work.ScoreWorkStatusID < 3 || this.work.ScoreWorkStatusID === 5) ? this.work.workParticipants : this.work.workParticipants.filter(({ UserSelected }) => +UserSelected === 1);
    // },

    participants() {
      const workStatusId = parseInt(this.work.ScoreWorkStatusID);

      return this.work.workParticipants.map(p => ({
        ...p,
        // disabled: (workStatusId === 5 && p.UserSelected) || [ 3, 4 ].includes(workStatusId) || p.StoppedAt,
        disabled: [ 3, 4 ].includes(workStatusId) || p.StoppedAt || (workStatusId === 5 && !this.isAdmin),
      }));

    },

    status() {
      return this.work.ScoreWorkStatusID;
    },

    statusTitle() {
      return this.$store.state.static.workStatuses.find(({ ScoreWorkStatusID }) => +ScoreWorkStatusID === +this.work.ScoreWorkStatusID)?.StatusTitle;
    },

    statusClass() {
      return this.currentStatusProps?.tagClass;

      // return this.scoreWorksStatusOptions(this.work.ScoreWorkStatusID)?.tagClass;
    },

    scoreWorkStatusID() {
      return parseInt(this.work.ScoreWorkStatusID);
    },

    disabledUploader() {
      return this.scoreWorkStatusID === 5;
    },

    showFullServices() {
      return [ 1, 2 ].includes(this.scoreWorkStatusID) || (this.scoreWorkStatusID === 5 && this.isAdmin);
    },

  },

  watch: {
    status() {

      this.showUploader = false;
    },
  },

  created() {
    if ( this.work.isOpened ) {
      this.isClosed = false;
    }
    this.fetchFiles();
  },

  methods: {

    scoreWorksStatusOptions: (statusId) => {
      const intStatus = parseInt(statusId);

      return this.workStatusProps[intStatus];
    },


    fetchFiles() {
      this.$store.dispatch('orderPage/fetchWorkDownloadedFiles', this.workId);
    },

    uploadFile(file) {
      file.customerId = this.orderDetails.CustomerIDSD;
      file.dealId = this.orderDetails.DealID;
      file.orderId = this.orderDetails.OrderID;
      file.workId = this.workId;
      this.$store.dispatch('orderPage/uploadFileToServer', file);
    },

    confirmDeleteAction() {
      if ( !this.deleteWorkItem ) {
        this.deleteWorkItem = true;
        this.setClickOut('deleteWorkItem');
      } else {
        this.showUploader = true;
        this.$emit('deleteWorkItem', this.work.ScoreWorkID);
      }
    },

    async confirmChangeWorkStatus() {
      if ( !this.changeWorkStatus ) {

        this.changeWorkStatus = true;
        this.setClickOut('changeWorkStatus');
      } else {

        const newScoreWorkStatusId = this.scoreWorkStatusID === 5 ? 3 : this.scoreWorkStatusID + 1;

        this.showUploader = true;
        this.$emit('changeWorkStatus', newScoreWorkStatusId, this.timeStampNow());
        this.changeWorkStatus = false;
      }
    },

    participantToggle(e, userId) {
      this.$emit('participantToggle', e.currentTarget.checked, userId, this.timeStampNow());
    },

    participantFinish(userId) {
      this.$emit('participantFinish', userId, this.timeStampNow());
    },

    setDateTime(date, defaultTitle) {
      return (date) ? `${ dateFormatDdMmYyyy(date) } в ${ dateTimeFormatHHMM(date) }` : defaultTitle;
    },

    timeStampNow() {
      const date = new Date();
      return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString();
    },

    async toggleWorkItem() {
      if ( this.isClosed && !this.work.hasDetails ) {
        this.showUploader = true;
        await this.$store.dispatch('scoreWorks/fetchOrderWorkDetails', this.work.ScoreWorkID);
        this.showUploader = false;
      }
      this.isClosed = !this.isClosed;
    },

    async additionButtonAction(actionName) {

      switch (actionName) {
        case 'changeWork': {
          this.showUploader = true;
          await this.$store.dispatch('scoreWorks/restoreOrderWorkFinished', this.work.ScoreWorkID);

          this.showUploader = false;

        }
      }

    },
  },
};
</script>
