<template>
  <Header @logOutApp="$emit('logEvent')" @load="$emit('logEvent')" />

  <template v-if="dataIsReady">
    <OrdersFilters/>

    <div class="elz cnnCardsList d-grid fn12 lh12 grH320 gap16 p16">
      <template v-for="order in orders">
        <OrdersCard
            v-if="filtered(order)"
            :key="order.OrderID"
            :order="order"
        />
      </template>
    </div>
  </template>

  <template v-else>
    <Uploader
        :circle-size="'s120'"
        :circle-width="'2'"
        :view-settings="'p-fixed p16 z1k bg bg-primary bgA80'"
    />
  </template>
</template>

<script>
import { useStore } from 'vuex';
import { onUnmounted } from 'vue';
import Header from '@/components/elements/Header';
import OrdersCard from '@/components/orders/OrdersCard';
import OrdersFilters from '@/components/orders/OrdersFilters';

export default {
  name: 'HomePage',

  components: {
    Header,
    OrdersCard,
    OrdersFilters,
  },

  emits: [ 'logEvent' ],


  setup() {
    console.log('home component');

    const store = useStore();

    store.dispatch('homePage/fetchOrders');

    store.dispatch('static/fetchFilters');

    onUnmounted(() => {
      store.commit('homePage/CLEAR_STATE');
    });
  },

  computed: {
    dataIsReady() {
      return this.$store.state.static.filters.readyState && this.$store.state.homePage.readyState;
    },

    orders() {
      return this.$store.state.homePage.orders;
    },
  },

  methods: {
    filtered(order) {
      return !Object.values(order.showInList).includes(false);
    },
  },
};
</script>
