<template>
  <Header @logOutApp="$emit('logEvent')"/>

  <template v-if="dataIsReady">
  <div class="elz d-block p16 wmx1200 mAuto">
    <BreadCrumbs
        :path="'Home'"
        :url="'/'"
    >
      Домой
    </BreadCrumbs>

      <div class="elz cnnFilterPanel p-sticky p-TEP d-flex mB16 mT8 gap16 ac-between f-wrap fn12 z10 bg bg-primary">
        <div class="elz cnnSelects d-flex f-wrap grow gap16">
          <SelectFilter
              v-model="userSelected"
              title="Подчиненные"
              :options="subordinate"
              @update:modelValue="getByCreatedUser"
          />
        </div>
      </div>

      <template v-for="(work, index) in works">
        <OrderWorksItem
            v-if="filtered(work)"
            :key="work.ScoreWorkID"
            :work="work"
            @changeWorkStatus="(status, timeStamp) => changeWorkStatus(work.ScoreWorkID, status, timeStamp)"
            @deleteWorkItem="deleteWorkItem"
            @participantFinish="(participantId, timeStamp) => participantFinish(index, participantId, timeStamp)"
            @participantToggle="(checked, participantId, timeStamp) => participantToggle(index, checked, participantId, timeStamp)"
            @updateServicesList="(id, checked, list) => updateServicesList(index, +id, checked, list)"
            @updateServiceCount="(id, count, list) => updateServiceCount(index, +id, count, list)"
        />
        </template>
      </div>
    </template>

  <template v-else>
    <Uploader
        :circle-size="'s120'"
        :circle-width="'2'"
        :view-settings="'p-fixed p16 z1k bg bg-primary bgA80'"
    />
  </template>
</template>


<script>
// import { useStore } from 'vuex';
// import { onUnmounted } from 'vue';
import Header from '@/components/elements/Header';
import BreadCrumbs from "@/components/elements/BreadCrumbs.vue";
import OrderWorksItem from "@/components/order/OrderWorksItem.vue";
import {useStore} from "vuex";
import {onUnmounted} from "vue";
import SelectFilter from "@/components/elements/SelectFilter.vue";


export default {
  name: 'WorkPage',

  components: {
    SelectFilter,
    OrderWorksItem,
    BreadCrumbs,
    Header,

  },

  emits: [ 'logEvent' ],

  setup() {
    const store = useStore();

    // const orderId = store.state.orderPage.ORDER_ID;

    if ( !store.state.static.scoreServices.length ) {
      store.dispatch('static/fetchScoreServices');
    }

    store.dispatch('scoreWorks/getOverdueWorks', 213);

    onUnmounted(() => {
      store.commit('scoreWorks/CLEAR_STATE');
    });
  },

  data() {
    return {
      userSelected: ''
    }
  },

  computed: {
    dataIsReady() {
      return this.$store.state.scoreWorks.readyState;
    },

    allWorks() {
      return this.$store.state.scoreWorks.works;
    },

    works() {
      const validStatuses = [2];

      return this.allWorks.filter(w => validStatuses.includes(w.ScoreWorkStatusID));
    },

    subordinate() {
      let filterSubordinate = [];
      const subordinateList = this.$store.state.scoreWorks.works;

      filterSubordinate.push({
        value: '',
        name: 'Все'
      })

      subordinateList.forEach((subordinate) => {
        let data = {};
        data.value = subordinate.CreatedBy;
        data.name = subordinate.LastName;

        if (filterSubordinate.includes(filterSubordinate.find(el => el.value === data.value))) {
          return
        }

        filterSubordinate.push(data);
      });

      return filterSubordinate;
    },

  },

  methods: {
    async changeWorkStatus(scoreWorkId, newStatus, timeStamp) {
      const work = this.$store.state.scoreWorks.works.find(w => w.ScoreWorkID === parseInt(scoreWorkId));

      // const workId = +this.works[index].ScoreWorkID;
      const workId = parseInt(scoreWorkId);
      const data = {
        workId: workId,
        params: { scoreWorkId: workId },
      };
      if ( newStatus === 2 ) {
        await this.$store.dispatch('scoreWorks/updateOrderWorkStarted', data);
        // this.works[index].StartedAt = timeStamp;
        if ( work ) {
          work.StartedAt = timeStamp;
        }
      } else if ( newStatus === 3 ) {
        await this.$store.dispatch('scoreWorks/updateOrderWorkFinished', data);
        // this.works[index].FinishedAt = timeStamp;
        if ( work ) {
          work.FinishedAt = timeStamp;
        }
      }
      // this.works[index].ScoreWorkStatusID = newStatus;
      if ( work ) {
        work.ScoreWorkStatusID = newStatus;
      }
    },

    async deleteWorkItem(id) {
      await this.$store.dispatch('scoreWorks/deleteOrderWork', id);
    },

    async participantFinish(index, participantId, timeStamp) {
      const user = this.works[index].workParticipants.find(({ UserID }) => +UserID === +participantId);
      user.showUploader = true;
      await this.$store.dispatch('scoreWorks/updateOrderWorkParticipant', {
        workId: +this.works[index].ScoreWorkID,
        participantId: +participantId,
      });
      user.StoppedAt = timeStamp;
      user.showUploader = false;
    },

    async updateServicesList(index, id, checked, list) {
      // list.showUploader = true;

      const params = this.getServiceParams(index, id);
      if ( checked ) {
        await this.$store.dispatch('scoreWorks/setOrderWorkService', params);
        params.service.serviceAmount = 1;
        const scoreParams = this.$store.state.static.scoreServicesRaw.find(({ ScoreServiceID }) => ScoreServiceID === id);
        params.service.serviceScore = scoreParams.ServiceScore;
        params.service.serviceTitle = scoreParams.ServiceTitle;
        this.works[index].workServices.push(params.service);
      } else {
        await this.deleteServiceItem(index, id, params);
      }
      this.countServicesSummary(index);

      list.showUploader = false;
    },

    async updateServiceCount(index, id, count, list) {
      // list.showUploader = true;

      const params = this.getServiceParams(index, id);
      count = parseInt(count, 0);
      if ( count === 0 ) {
        await this.deleteServiceItem(index, id, params);
      } else {
        params.service.serviceAmount = count;
        await this.$store.dispatch('scoreWorks/updateOrderWorkService', params);
        this.works[index].workServices.find(({ scoreServiceId }) => +scoreServiceId === +id).serviceAmount = count;
      }
      this.countServicesSummary(index);

      list.showUploader = false;
    },

    async participantToggle(index, checked, participantId, timeStamp) {
      const work = this.works[index];

      const user = work.workParticipants.find(({ UserID }) => +UserID === +participantId);

      const statusId = parseInt(work.ScoreWorkStatusID);

      user.showUploader = true;
      const data = {
        workId: +this.works[index].ScoreWorkID,
      };
      if ( checked ) {
        data.params = { participantUserId: +participantId };
        await this.$store.dispatch('scoreWorks/setOrderWorkParticipant', data);
        user.CreatedAt = statusId === 5 ? work.StartedAt : timeStamp;
        user.UserSelected = 1;
      } else {
        data.participantUserId = +participantId;
        await this.$store.dispatch('scoreWorks/deleteOrderWorkParticipant', data);
        user.CreatedAt = '';
        user.UserSelected = 0;
      }
      checked ? this.works[index].UserCount += 1 : this.works[index].UserCount -= 1;
      user.showUploader = false;
    },


    filtered(work) {
      if (!+this.userSelected) {
        return true;
      }
      return work.CreatedBy === +this.userSelected;
    },

    getByCreatedUser() {
      this.works.forEach(work => {
        if (+work.CreatedBy === +this.userSelected) {
          console.log(work);
        }
        work = (+work.CreatedBy === +this.userSelected);
      })
    },
  }
}

</script>