import AppDataServ from '@/services/AppDataServ';
import { dateCalculateDifference } from '@/helpers/formating';

export const ModuleHome = () => {
  return {
    namespaced: true,

    state: () => ({
      orders: [],
    }),

    mutations: {
      CLEAR_STATE(state) {
        state.orders = [];
        state.readyState = false;
      },

      SET_ORDERS(state, data) {
        const orders = [];
        data.forEach(order => orders.push(prepareOrder(order)));
        //state.headerIcons.case = orders.filter(el => +el.TroubleStatusID === 2).length;
        //state.headerIcons.clock = orders.filter(el => el.overdueSummary < 0,24).length;
        state.orders = orders;
        state.readyState = true;
      },

      UPDATE_ORDERS_LIST(state, data) {
        const order = data[0];
        if ( !state.orders.find(({ OrderID }) => +OrderID === +order.OrderID) ) {
          state.orders.unshift(prepareOrder(order));
        }
      },
    },

    actions: {
      fetchOrders({ commit }, token) {
        return AppDataServ.getOrders(token)
          .then(response => {
            commit('SET_ORDERS', response.data);
          })
          .catch(error => {
            throw(error);
          });
      },

      fetchOrderSingleSearch({ commit }, orderId) {
        return AppDataServ.getOrderSingleSearch(orderId)
          .then(response => {
            commit('UPDATE_ORDERS_LIST', response.data);
          })
          .catch(error => {
            throw(error);
          });
      },
    },
  };
};

export const prepareOrder = (order) => {
  const diff = order.Deadline ? dateCalculateDifference(new Date(), new Date(order.Deadline)) : dateCalculateDifference();
  order.overdueTitle = diff.title;
  order.overdueSummary = diff.summary;
  order.showInList = {
    byNumber: true,
    byPriority: true,
    byRole: true,
    byStatus: true,
    byDepartment: true,
    byType: true,
  };
  return order;
};
